body {
  margin: 0;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-osx-font-smoothing: grayscale;
  background: rgba(29, 31, 35, 0.15);
  height: 100vh;
  width: 100vw;
}

#root {
  height: 100%;
}

.gm-style-iw-d {
  overflow: hidden !important;
}

.gm-ui-hover-effect {
  display: none !important;
}
/* Force position to show pegman */
.gm-svpc div img {
  position: relative !important;
}

/* remove div which overlay google map markers
making them unclickable */
.gm-style-moc {
  display: none;
}
